<template>
  <v-card min-width="250">
    <v-card-title>
      <v-spacer></v-spacer>
      Dados Sincronismo
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <div v-for="(item, i) in value.response" :key="item.url">
        <v-row>
          <v-col>
            <span class="font-weight-black">IP: <span class="font-weight-medium">{{ value.ips[i].ip }}</span></span>
          </v-col>
          <v-col cols="auto" v-show="item.error || false">
            <v-spacer></v-spacer>
            <v-icon color="error">mdi-thumb-down</v-icon>
          </v-col>
          <v-col cols="auto" v-show="item.success || false">
            <v-spacer></v-spacer>
            <v-icon color="success">mdi-thumb-up</v-icon>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col>
            <span class="font-weight-black">ZONA: <span class="font-weight-medium">{{ value.zoneCode }}</span></span>
          </v-col>
          <v-col>
            <span class="font-weight-black">EVENTO: <span class="font-weight-medium">{{ value.eventCode }}</span></span>
          </v-col>
        </v-row>
        <v-divider class="my-2" v-if="item.error"></v-divider>
        <v-row v-if="item.error">
          <v-col>
            <span class="font-weight-black">CODIGO: <span class="font-weight-medium">{{ item.error.code || "" }}</span></span>
          </v-col>
          <v-col>
            <span class="font-weight-black">DADOS: <pre class="font-weight-medium">{{ stringfy(item.error.data || "") }}</pre></span>
          </v-col>
        </v-row>
      </div>
      <v-row v-show="value.disabledSync">
        <v-spacer></v-spacer>
        <v-col cols="auto" align-self="center">Sincronização Desabilitada</v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row v-show="value.notSync">
        <v-spacer></v-spacer>
        <v-col cols="auto" align-self="center">Evento não Sincronizado</v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SyncPayload",
  props: ["value", "event"],
  methods: {
    stringfy(json) {
      return JSON.stringify(json, null, 2);
    },
  },
};
</script>

<style></style>