<template>
  <v-card min-width="300">
    <v-card-text>
      <v-row v-if="value.occurrence">
        <v-col cols="auto">Conta:</v-col>
        <v-col cols="auto">{{value.occurrence.accountId}} - {{value.occurrence.accountName}}</v-col>
      </v-row>
      <v-row v-if="value.occurrence">
        <v-col cols="auto">Usuário:</v-col>
        <v-col cols="auto">{{value.occurrence.userId}} - {{value.occurrence.userName}}</v-col>
      </v-row>
      <v-row v-if="value.occurrence">
        <v-col cols="auto">Tratamento:</v-col>
        <v-col cols="auto">{{value.occurrence.resolution}}</v-col>
      </v-row>
      <v-row v-if="value.occurrence">
        <v-col cols="auto">Chegou:</v-col>
        <v-col cols="auto">{{new Date(value.occurrence.createDate).toLocaleString()}}</v-col>
      </v-row>
      <v-row v-if="value.occurrence">
        <v-col cols="auto">Atendeu:</v-col>
        <v-col cols="auto">{{new Date(value.occurrence.attendanceDate).toLocaleString()}}</v-col>
      </v-row>
      <v-row v-if="value.occurrence">
        <v-col cols="auto">Fechou:</v-col>
        <v-col cols="auto">{{new Date(value.occurrence.closeDate).toLocaleString()}}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SoftwareTratamento",
  props: ["value"],
}
</script>

<style>

</style>